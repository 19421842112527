<template>
  <v-list
    dense
    width="400"
    elevation="2"
    class="py-0"
    style="position: relative"
  >
    <v-container fluid class="pa-0 message-list">
      <v-list-item class="py-6 fixed-top">
        <v-list-item-content>
          <v-list-item-title class="title"> Sent mails </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-icon>mdi-menu-down</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-list-item-group v-model="selectedInboxMail">
        <template v-for="(item, i) in showMails">
          <v-list-item :key="i">
            <div
              class="message-tile py-5"
              @click="$router.push(`/mail/sent/${item.uniqueid}`)"
            >
              <v-container fluid>
                <v-row>
                  <v-col sm="10" class="pa-0">
                    <v-list-item-content>
                      <v-list-item-title
                        class="mb-2 font-weight-bold message-tile--sender"
                        v-text="getUserName(item)"
                      ></v-list-item-title>

                      <v-list-item-subtitle
                        class="text--primary message-tile--subject"
                        v-text="item.subject"
                      ></v-list-item-subtitle>

                      <v-list-item-subtitle
                        v-text="item.body"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-col>

                  <v-col sm="2" class="px-0">
                    <v-list-item-action class="message-tile--details">
                      <v-list-item-action-text class="text-uppercase">
                        {{
                          new Date(item.timestamp).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                        }}
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-container>
  </v-list>
</template>

<script>
export default {
  computed: {
    routeName() {
      return this.$route.name;
    },
    showMails() {
      return this.$store.getters.getSent;
    },
  },
  methods: {
    getUserName(item) {
      let names = "";

      const temp = [...item.receiver];
      if (temp.length) {
        const u_1 = item.user_details.filter((i) => i.emailId == temp[0]);

        if (!u_1.length) names = "<" + temp[0] + ">";
        else names = u_1[0].firstName + " " + u_1[0].lastName;
        temp.shift();
      }

      temp.forEach((mail) => {
        const user = item.user_details.filter((i) => i.emailId == mail);

        if (!user.length) names += ", <" + mail + ">";
        else names += ", " + user[0].firstName + " " + user[0].lastName;
      });
      return names;
    },
  },
  data() {
    return {
      selectedInboxMail: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.message-tile {
  border-bottom: 1px solid #cccccc;
  width: 100%;

  &--sender {
    font-size: 1rem !important;
    line-height: initial !important;
  }

  &--subject {
    font-size: 0.85rem !important;
    line-height: 1.8 !important;
  }

  &--details {
    margin: 0 !important;
  }
}

.message-list {
  position: absolute;
  overflow: auto;
  height: 100%;
  top: 0;

  .fixed-top {
    position: sticky;
    top: 0;
    background: white;
    z-index: 2;
    box-shadow: 0 0 7px #dddddd;
  }
}
</style>